<template>
  <!-- content-section:내 등급 -->
  <section class="content-section">
    <div class="my-grade">
      <div class="grade-content">
        <span class="content-subtitle">Lv5 등급</span>
        <h3 class="content-title"><a href="javascript:" class="title-link"><span class="title">국민대스타</span><i class="icon-arrow"></i></a></h3>
        <p class="content-description">현재 이국민님은 Lv5 상위 28%에요. 조금 더 분발하면 <br/>우주 대스타가 될 수 있어요!</p>
      </div>
      <div class="grade-icon">
        <img src="../../../assets/lxp/images/badge/grade_5.svg" alt="지구촌스타 등급" />
      </div>
    </div>
  </section>
  <!-- //content-section:내 등급 -->
  <!-- content-section:내 배지 -->
  <section class="content-section content-group-divider">
    <header class="section-header">
      <h4 class="title">내 배지<span class="text text-muted">{{items.length}}</span></h4>
    </header>
    <div class="badge-list-container">
      <!-- TODO: 달성한 배지가 없어요 -->
      <div v-if="items.length===0" class="contents-empty">
        <p class="text">달성한 배지가 없어요 <br/>학습으로 배지를 획득하세요!</p>
      </div>
      <ul v-else class="badge-list">

        <!-- badge-item -->
        <li v-for="(item, idx) in items" :key="idx" class="badge-item">
          <div class="kb-badge">
            <div class="badge-icon">
              <div v-if="item.newYn === 'y'" class="badge"><span class="badge-text">NEW</span></div>
              <!-- 뱃지이미지 url 수정필요 -->
<!--              <img :src="item.badgeUrl" alt="" class="icon" />-->
              <img src="../../../assets/lxp/images/badge/badge_01_active.svg" alt="" class="icon">
            </div>
            <div class="badge-content">
              <strong class="title" style="white-space: normal; word-break:break-all">{{ item.badgeName }}</strong>
              <span class="text">{{item.myCount}}/{{item.totalCount}}</span>
            </div>
          </div>
        </li>
        <!-- badge-item -->

      </ul>
    </div>
  </section>
  <!-- //content-section:내 배지 -->
</template>

<script>
import {useStore} from "vuex";
import {ref} from "vue";
import {getItems, lengthCheck} from "@/assets/js/util";
import {ACT_GET_MY_BADGE_LIST} from "@/store/modules/my/my";

export default {
  name: 'MyBadge',
  setup(){
    const store = useStore();

    const items = ref([]);

    const getMyBadgeList = () => {
      store.dispatch(`my/${ACT_GET_MY_BADGE_LIST}`, {
      }).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
        }else{
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getMyBadgeList();

    return{
      items,
      getMyBadgeList,
    }
  }
};
</script>